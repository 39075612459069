import { ReactElement, useEffect, useState } from 'react';
import { Datum, ResponsiveLine } from '@nivo/line';
import axios from 'axios';

import { Button, Checkbox, Chip } from '@material-tailwind/react';
import { ChartProps } from '../../types/interface';
import { convertDateString } from '../../utils/DateFormat';
import { ApplovinType } from '../../types/type';

const NetworkName = [
  'ADMOB_BIDDING',
  'APPLOVIN',
  'FACEBOOK_NETWORK',
  'INMOBI_BIDDING',
  'UNITY_NETWORK',
  'UNITY_BIDDING',
  'VUNGLE_BIDDING',
];

const NetworkNameString = {
  ADMOB_BIDDING: 'ADMOB',
  APPLOVIN: 'MAX',
  FACEBOOK_NETWORK: 'FB',
  INMOBI_BIDDING: 'INMOBI',
  UNITY_NETWORK: 'UNITY',
  UNITY_BIDDING: 'UNITY',
  VUNGLE_BIDDING: 'VUNGLE',
};

const PropertName: { [key: string]: string } = {
  impressions: '노출',
  ecpm: 'ECPM',
  estimatedRevenue: '수익',
};

export default function ApplovinChart({
  startDate,
  endDate,
}: ChartProps): ReactElement {
  const [useNetworks, setUseNetworks] = useState(NetworkName);
  const [data, setData] = useState<ApplovinType[]>([]);
  const [chartData, setChartData] = useState<
    {
      id: string;
      color: string;
      data: Datum[];
    }[]
  >([]);
  const [property, setProperty] = useState<
    'impressions' | 'ecpm' | 'estimatedRevenue'
  >('estimatedRevenue');
  const [profit, setProfit] = useState(0);
  const [networkProfit, setNetworkProfit] = useState<
    {
      network: string;
      profit: number;
    }[]
  >([]);

  // impressions,,ecpm
  const getData = async (): Promise<void> => {
    const start = new Date(`${startDate}T00:00:00`);
    const end = new Date(`${endDate}T23:59:59`);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    axios
      .get(
        `https://ad-report-api.moneymoa.net/max/daily?start=${start.toISOString()}&end=${end.toISOString()}&columns=network&filterApplication=${'달러 캐시 - 돈 버는 앱, 달러 버는 앱, 앱테크'}&timezone=${timezone}`
      )
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    if (startDate === '' || endDate === '') {
      return;
    }
    if (new Date(endDate).getTime() - new Date(startDate).getTime() < 0) {
      return;
    }

    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (data.length < 0) {
      return;
    }

    const result: {
      id: string;
      color: string;
      data: Datum[];
    }[] = [];
    setChartData([]);

    const tempNetworkProfit: {
      network: string;
      profit: number;
    }[] = [];

    const networks = Array.from(
      new Set(
        data
          .filter(({ network }) => {
            let flag = false;
            useNetworks.forEach((value) => {
              if (
                value === network ||
                (network && network?.includes('APPLOVIN'))
              ) {
                flag = true;
              }
            });
            return flag;
          })
          .map((value) => value.network)
      )
    );

    const applovinSum: ApplovinType[] = [];
    data
      .filter(({ network }) => {
        let flag = false;
        if (network && network.includes('APPLOVIN')) {
          flag = true;
        }
        return flag;
      })
      .forEach((newValue) => {
        let isExist = false;
        const { time, impressions, estimatedRevenue, responses, attempts } =
          newValue;

        applovinSum.forEach((existValue, index) => {
          if (existValue.time === time) {
            applovinSum[index].impressions += Number(impressions);
            applovinSum[index].estimatedRevenue += Number(estimatedRevenue);
            applovinSum[index].responses += Number(responses);
            applovinSum[index].attempts += Number(attempts);
            applovinSum[index].fillRate =
              applovinSum[index].responses / applovinSum[index].attempts;
            applovinSum[index].ecpm =
              (applovinSum[index].estimatedRevenue /
                applovinSum[index].impressions) *
              1000;
            isExist = true;
          }
        });
        if (!isExist) {
          applovinSum.push({
            time,
            network: 'APPLOVIN',
            impressions: Number(impressions),
            estimatedRevenue: Number(estimatedRevenue),
            responses: Number(responses),
            attempts: Number(attempts),
            fillRate: Number(responses) / Number(attempts),
            ecpm: (Number(estimatedRevenue) / Number(impressions)) * 1000,
          });
        }
      });

    let networkProfitSum = 0;
    const applovinData = applovinSum.map((value) => {
      networkProfitSum += Number(value.estimatedRevenue);
      return {
        x: new Date(value.time),
        y: value[property],
      };
    });

    tempNetworkProfit.push({
      network: 'APPLOVIN',
      profit: networkProfitSum,
    });

    networks.forEach((network) => {
      networkProfitSum = 0;
      const networkData = data
        .filter((value) => value.network === network)
        .map((value) => {
          networkProfitSum += Number(value.estimatedRevenue);
          return {
            x: new Date(value.time),
            y: value[property],
          };
        });
      if (!network?.includes('APPLOVIN')) {
        result.push({ id: network!, color: '', data: networkData });
        tempNetworkProfit.push({
          network: network!,
          profit: networkProfitSum,
        });
      }
    });

    if (useNetworks.includes('APPLOVIN')) {
      result.push({ id: 'APPLOVIN', color: '', data: applovinData });
    }

    setNetworkProfit(
      tempNetworkProfit
        .sort((a, b) => a.network.localeCompare(b.network))
        .filter((networkSumData) =>
          useNetworks.includes(networkSumData.network)
        )
    );

    result.sort((a, b) => b.id.localeCompare(a.id));

    const sum: ApplovinType[] = [];
    data
      .filter(({ network }) => {
        let flag = false;
        useNetworks.forEach((value) => {
          if (
            value === network ||
            (value === 'APPLOVIN' && network?.includes('APPLOVIN'))
          ) {
            flag = true;
          }
        });
        return flag;
      })
      .forEach((newValue) => {
        let isExist = false;
        const { time, impressions, estimatedRevenue, responses, attempts } =
          newValue;
        sum.forEach((existValue, index) => {
          if (existValue.time === time) {
            sum[index].impressions += Number(impressions);
            sum[index].estimatedRevenue += Number(estimatedRevenue);
            sum[index].responses += Number(responses);
            sum[index].attempts += Number(attempts);
            sum[index].fillRate = sum[index].responses / sum[index].attempts;
            sum[index].ecpm =
              (sum[index].estimatedRevenue / sum[index].impressions) * 1000;
            isExist = true;
          }
        });
        if (!isExist) {
          sum.push({
            time,
            network: '합계',
            impressions: Number(impressions),
            estimatedRevenue: Number(estimatedRevenue),
            responses: Number(responses),
            attempts: Number(attempts),
            fillRate: Number(responses) / Number(attempts),
            ecpm: (Number(estimatedRevenue) / Number(impressions)) * 1000,
          });
        }
      });

    let profitSum = 0;
    const sumData = sum.map((value) => {
      profitSum += value.estimatedRevenue;
      setProfit(profitSum);

      return {
        x: new Date(value.time),
        y: value[property],
      };
    });

    if (useNetworks.length > 1) {
      result.push({ id: '합계', color: '', data: sumData });
    }

    setChartData(result);
  }, [data, useNetworks, property]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex items-center justify-center rounded-full">
        <Chip
          value={`총 수익 $${Math.floor(profit)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${(
            profit - Math.floor(profit)
          )
            .toFixed(2)
            .toString()
            .replace('0.', '')}`}
          size="sm"
          variant="ghost"
          color="blue"
          className="w-fit rounded-full"
        />
      </div>
      {networkProfit.length > 1 && (
        <div className="mt-2 flex flex-row items-center justify-center gap-1 rounded-full">
          {networkProfit.map((value) => (
            <Chip
              key={value.network}
              value={`$${Math.floor(value.profit)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${(
                value.profit - Math.floor(value.profit)
              )
                .toFixed(2)
                .toString()
                .replace('0.', '')}`}
              size="sm"
              variant="ghost"
              color="blue"
              className="w-fit rounded-full"
            />
          ))}
        </div>
      )}
      <div className="flex w-full flex-row items-center justify-center gap-4">
        {NetworkName.map((network, index) => (
          <Checkbox
            key={index}
            id={network}
            label={
              <div className="text-xs">
                {NetworkNameString[network as keyof typeof NetworkNameString]}
              </div>
            }
            checked={useNetworks.includes(network)}
            onChange={(e): void => {
              if (e.target.checked) {
                setUseNetworks([...useNetworks, e.target.id]);
              } else {
                setUseNetworks(
                  useNetworks.filter((value) => value !== e.target.id)
                );
              }
            }}
          />
        ))}
        <Checkbox
          className="flex-1"
          label={<div className="text-xs">ALL</div>}
          checked={useNetworks.length === NetworkName.length}
          onChange={(e): void => {
            if (e.target.checked) {
              setUseNetworks(NetworkName);
            } else {
              setUseNetworks([]);
            }
          }}
        />
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-4">
        <Button
          className="mx-5 flex-1 p-2 text-center"
          onClick={(): void => setProperty('estimatedRevenue')}
        >
          수익
        </Button>
        <Button
          className="mx-5 flex-1 p-2 text-center"
          onClick={(): void => setProperty('impressions')}
        >
          노출
        </Button>
        <Button
          className="mx-5 flex-1 p-2 text-center"
          onClick={(): void => setProperty('ecpm')}
        >
          ecpm
        </Button>
      </div>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 20, right: 30, bottom: 50, left: 70 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        yFormat={` >-${property !== 'impressions' ? '$,.2f' : ',d'}`}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 4,
          format: (value): string => convertDateString(value),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: PropertName[property],
          legendOffset: -55,
          legendPosition: 'middle',
          format: (value): string =>
            `${property !== 'impressions' ? '$' : ''}${value}`,
        }}
        enableSlices="x"
        sliceTooltip={({ slice }): ReactElement => (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <strong>
              {convertDateString(new Date(slice.points[0].data.x))}
            </strong>
            {slice.points.map((point) => (
              <div key={point.id} className="flex flex-row gap-2 py-1">
                <div
                  style={{
                    color: point.serieColor,
                  }}
                >
                  ■
                </div>
                {point.serieId} <strong>{point.data.yFormatted}</strong>
              </div>
            ))}
          </div>
        )}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
}
